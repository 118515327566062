<template>
  <v-slide-y-transition class="py-0" tag="v-card">
    <v-card elevation="5" :style="style" :color="cardColour"  v-show="!dismissed">
      <v-card-title>
        <h2 @click="navigateToQuiz" class="ma-inverse-text" style="cursor:pointer;">What to do first?</h2>
      </v-card-title>
      <v-card-text>
        <div class="ma-inverse-text">
          Find where you belong on the Pathway using this one-time competency test.
        </div>
      </v-card-text>
      <v-card-actions class="justify-end pr-8">
        <v-btn data-test-id="btnDismiss" @click="dismiss" color="white" x-large class="px-6" text>Dismiss</v-btn>
        <v-btn data-test-id="btnStartHere" @click="navigateToQuiz" color="primary" x-large class="px-8">Start here!</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "DiagnosticCard",
  data() {
    return {/**/
      dismissed: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['data']),
    quiz() {
      return this.data.diagnostic.quiz;
    },
    style() {
      return `border: solid 0.25rem #2c5e8b`;
    },
    cardColour() {
      return "#2c5e8b88";
    }
  },
  methods: {
    ...mapActions('dashboard', ['dismissCard']),
    async dismiss() {
      await this.dismissCard('DiagnosticCard');
      this.dismissed = true;
    },
    navigateToQuiz() {
      this.$router.push({name: 'diagnostic', params: { slug: this.quiz.slug}});
    },
  }
}
</script>

<style scoped lang="scss">
.ma-inverse-text {
  color: rgba(255,255,255,0.9);
  text-shadow: 1px 1px 6px rgba(0,0,0,0.8);
}

.v-card__title {
  display: flex;

  h2 {
    flex: 1 1 0;
  }
}
</style>
