<template>
  <v-app>
    <v-main class="main">
      <v-container>
        <v-card class="rounded-xl pt-sm-12 pr-sm-7 pb-sm-9 pl-sm-15 pa-10 mt-10" style="max-width: 1200px;">
          <v-row align="start">
            <v-col md="6" sm="6" order-sm="1" order-md="1" order-lg="1" order-xl="1" cols="12" order="2">
              <WelcomeText app-title="MusicApprentice" />
              <div class="action">
                <v-btn color="indigo" data-test-id="btnCreateAccount" class="white--text" depressed @click="signup" large>Create account</v-btn>
                <v-btn color="indigo" data-test-id="btnLogIn"  outlined @click="login" large>Log in</v-btn>
              </div>
            </v-col>
            <v-col md="6" sm="6" align-self="start" order-sm="2" order-md="2" order-lg="2" order-xl="2" order="1" cols="12">
              <img src="../../assets/studio/illustration.png" class="studio-img"/>
              <img src="https://content.mymatt.com.au/logo-secondary.svg" width="auto" height="100" class="black-logo" alt="">
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import WelcomeText from "../common/WelcomeText.vue";

export default {
  name: "Welcome",
  components: { WelcomeText },
  watch: {
    "$auth.isAuthenticated": {
      handler: function (val) {
        if (val) {
          this.$router.push("/").catch((err) => { /*don't care*/ });
        }
      },
      deep: true,
      immediate:true
    },
  },
  methods: {
    login() {
      this.$auth.doLogin();
    },
    signup() {
      this.$auth.doLogin({ screen_hint: 'signup' });
    }
  },
};
</script>

<style lang="scss">
.main {
  background-image: url("../../assets/studio/background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.studio-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.black-logo {
  position: absolute;
  top: 60px;
  right: 70px;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
