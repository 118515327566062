<template>
  <v-card elevation="5">
    <v-card-title>
      <h2 v-text="title" />
      <v-btn icon @click="doDismiss" v-if="dismissable">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="flex-and-gap">
      <SpeechPlayerController :resource="card" field="content.html" />
      <div v-html="content"/>
    </v-card-text>

    <v-card-actions class="mx-8 pb-8" v-if="hasAttachments">
      <ResourceAttachmentsList :attachments="card.metadata.attachments" />
    </v-card-actions>
  </v-card>
</template>

<script>

import SpeechPlayerController from "../../common/SpeechPlayerController.vue";
import {mapActions} from "vuex";
import ResourceAttachmentsList from "../../studio/components/ResourceAttachmentsList.vue";

export default {
  name: "ContentCard",
  components: {ResourceAttachmentsList, SpeechPlayerController},
  props: {
    card: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    dismissable: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    hasAttachments() {
      return this.card.metadata?.attachments?.length > 0;
    },
    slug() {
      return this.card.slug;
    },
    title() {
      return this.card.title;
    },
    content() {
      return this.card.content.html;
    },
    speechUrl() {
      return this.card.content.speechUrl;
    },
  },
  methods: {
    ...mapActions('dashboard', ['dismissCard']),
    doDismiss() {
      this.dismissCard(this.name);
    },
  },
}
</script>

<style scoped lang="scss">
.v-card__title {
  display: flex;

  h2 {
    flex: 1 1 0;
  }
}

</style>