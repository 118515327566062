<template>
  <div class="ma-tag-div" @click="$emit('click')">
    <v-tooltip bottom :disabled="!hasTooltip">
      <template v-slot:activator="{on}">
        <span class="ma-tag" :style="tagStyle" v-on="on">{{ label }}</span>
      </template>
      <template v-slot:default>
        <div v-html="tooltipText"></div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
// tag = {id, slug, type, name, description, metadata.colour, reportable}

export default {
  name: "Tag",
  props: {
    tag: Object,
  },
  computed: {
    tagStyle() {
      return {
        backgroundColor: this.tag.metadata?.colour || '#bbb'
      };
    },
    hasTooltip() {
      return !!this.tag.content?.description;
    },
    tooltipText() {
      return this.tag.content?.description;
    },
    label() {
      return this.tag.name;
    }
  },
}
</script>

<style scoped lang="scss">
@import '../style';

.ma-tag-div {
  display: inline-block;
  cursor: pointer;

  .ma-tag {
    display: inline-block;
    color: transparentize(black, 0.4);
    font-weight: 400;
    border: solid 1px transparent;
    box-shadow: 0 0 0 .5px rgba(0,0,0,0.3);

    &:hover {
      box-shadow: 0 0 0 0.5px rgba(0,0,0,0.2), 1px 1px 4px rgba(0,0,0,0.6);
    }
  }
}
</style>