import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request.js";
import teacher from "./teacher.js";

const {MATT_USER_API} = window;

Vue.use(Vuex);

export default {
  namespaced: true,
  modules: {
    teacher,
  },
  state: {
    submissions: [],
  },
  mutations: {
    setSubmissions(state, data) {
      state.submissions = data;
    },
  },
  actions: {
    async loadStudentSubmissions({commit, dispatch}, filters) {
      try {
        const studentId = filters?.studentId ?? 'me';
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/submissions/${studentId}`, {params: filters});
        commit('setSubmissions', response.data.quizSubmissions);

        if (studentId !== 'me') {
          dispatch('teacher/loadStudent', studentId);
        }

        return response.data;
      } catch (error) {
        console.error('Error loading student submissions', error);
        throw error;
      }
    },
    async loadCohortSubmissions({commit}, filters) {
      const cohortId = filters?.cohortId ?? 'all';
      const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/submissions/cohort/${cohortId}`, { params: filters });
      commit('setSubmissions', response.data.quizSubmissions);
      return response.data;
    },
  },
};
