export default {
  props: {
    attachments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    hasAttachments() {
      return this.attachments.length > 0;
    },
  },
  methods: {
    getIconForMimeType(mimetype) {
      if(mimetype.startsWith('image')) return 'mdi-image';
      if(mimetype.startsWith('audio')) return 'mdi-file-music';
      if(mimetype.startsWith('video')) return 'mdi-file-video';
      if(mimetype.includes('pdf')) return 'mdi-file-pdf-box';
      if(mimetype.includes('msword')) return 'mdi-file-word';
      if(mimetype.includes('zip')) return 'mdi-zip-box';
      return 'mdi-file';
    },
    downloadAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.url;
      link.download = attachment.title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
}