import Vuex from 'vuex'
import Vue from "vue";
import {delWithId, getWithId, patchWithId, postWithId} from "../request.js";
import ui from "./ui.js";

Vue.use(Vuex);

const {MATT_USER_API} = window;

export default {
  namespaced: true,
  modules: { ui },
  state: {
    initialData: null,
    account: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    student: {
      firstName: '',
      lastName: '',
      displayName: ''
    },
    teacher: {
      displayName: ''
    },
    myTeachers: [],
    subscription: {},
    myTeacherCode: null,
    activeSubmission: {},
    userPreferences: {},
  },
  mutations: {
    setInitialData(state, data) {
      state.initialData = data;
    },
    setMyTeachers(state, resources) {
      state.myTeachers = resources;
    },
    setUser(state, user) {
      if (!user) {
        return;
      }

      const userData = user["https://musicapprentice.com.au/userdata"];
      const appData = user["https://musicapprentice.com.au/appdata"];

      const defaultAccount = {
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        phone: user.phone_number,
      }

      state.subscription = appData?.subscription;
      state.myTeacherCode = appData?.teacherCode;
      state.student = userData.student || {};
      state.teacher = userData.teacher || {};
      state.account = userData.account || defaultAccount;
      state.account.email = state.account.email || user.email;
    },
    setActiveSubmission(state, data) {
      state.activeSubmission = data;
    },
    setUserPreferences(state, preferences) {
      state.userPreferences = preferences;
    },
  },
  actions: {
    async initialise({commit, dispatch}) {
      commit('setUser', this._vm.$auth?.user);
      await Promise.all([
        dispatch('loadSubscriptionData'),
        dispatch('loadUserPreferences'),
      ]);
    },
    async loadSubscriptionData({ state, commit, dispatch }) {
      await dispatch('ui/setLoading', 'Loading subscription data...');

      try {
        if (state.initialData) {
          return state.initialData;
        }

        const url = `${MATT_USER_API}/subscription/current`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data.data;
        commit('setInitialData', data);

        return data;
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async registerLogin() {
      const url = `${MATT_USER_API}/loggedIn`;
      const response = await postWithId(this._vm.$auth, url, {});
      return response.data;
    },
    async updateUserData({state, dispatch}) {
      await dispatch('ui/setLoading', 'Updating your profile...');

      try {
        const url = `${MATT_USER_API}/userData/me`;
        const payload = {
          userData: {
            teacher: state.teacher,
            student: state.student,
            account: state.account,
          }
        }
        const response = await patchWithId(this._vm.$auth, url, payload);
        dispatch('ui/showSuccess', 'Successfully updated profile!');
        return response.data;
      } catch (err) {
        dispatch('ui/showError', 'Error updating profile');
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async deleteTeacher({dispatch}, teacherUserId) {
      await dispatch('ui/setLoading', 'Removing your teacher...');

      try {
        const response = await delWithId(this._vm.$auth, `${MATT_USER_API}/student/teachers/${teacherUserId}`);
        dispatch('ui/showSuccess', 'Teacher successfully removed!');
        dispatch('loadMyTeachers', true);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error removing teacher');
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async addTeacherCode({dispatch}, code) {
      await dispatch('ui/setLoading', 'Adding your teacher code...');

      try {
        const response = await postWithId(this._vm.$auth, `${MATT_USER_API}/student/teachers`, {code});
        dispatch('ui/showSuccess', 'Teacher successfully added!');
        await dispatch('loadMyTeachers');
        return response.data;
      } catch (err) {
        console.error(err);
        const message = err.response?.data?.message || 'Error adding teacher code';
        dispatch('ui/showError', message);
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async loadMyTeachers({commit,state, dispatch}, force=false) {
      if(!state.myTeachers.length || force) {
        try {
          await dispatch('ui/setLoading', 'Loading your teachers...');
          const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/student/teachers`);
          commit('setMyTeachers', response.data.data);
        } finally {
          await dispatch('ui/setLoading', null);
        }
      }
    },
    async useSubscriptionCode({dispatch}, subKey) {
      await dispatch('ui/setLoading', 'Applying subscription code...');

      try {
        const url = `${MATT_USER_API}/subscription`;
        const payload = {
          code: subKey.toUpperCase()
        }

        const response = await postWithId(this._vm.$auth, url, payload);

        const {status, message} = response.data;
        if (status === 'accepted') {
          await dispatch('ui/showSuccess', message);
          await this._vm.$auth.forceTokenRefresh();
          location.reload();
        } else if (status === 'rejected') {
          dispatch('ui/showError', message);
        }

        return response.data;
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Could not apply subscription code. Contact administrator.');
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async cancelSubscription({dispatch}) {
      await dispatch('ui/setLoading', 'Cancelling your subscription...');

      try {
        const url = `${MATT_USER_API}/subscription`;
        await delWithId(this._vm.$auth, url);
        await dispatch('ui/showSuccess', 'Successfully cancelled subscription!');
        await this._vm.$auth.forceTokenRefresh();
        location.reload();
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Could not cancel existing subscription. Contact administrator.');
        throw err;
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async loadUserPreferences({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading user preferences...');

      try {
        const url = `${MATT_USER_API}/userPreferences`;
        const response = await getWithId(this._vm.$auth, url);
        commit('setUserPreferences', response.data.userPreferences.data);
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error loading user preferences');
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
    async updateUserPreferences({commit, state, dispatch}) {
      await dispatch('ui/setLoading', 'Updating user preferences...');

      try {
        const url = `${MATT_USER_API}/userPreferences`;
        const payload = { data: state.userPreferences };
        const response = await patchWithId(this._vm.$auth, url, payload);
        commit('setUserPreferences', response.data.userPreferences.data);
        dispatch('ui/showSuccess', 'User preferences updated successfully');
      } catch (err) {
        console.error(err);
        dispatch('ui/showError', 'Error updating user preferences');
      } finally {
        dispatch('ui/setLoading', null);
      }
    },
  },
};
