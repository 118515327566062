<template>
  <div v-if="hasAttachments" class="ma-card-attachments">
    <h4>Attachments</h4>
    <v-list class="ma-0 pa-0" color="rgba(0,0,0,0)">
      <v-list-item v-for="(attachment, index) in attachments" :key="index" @click="downloadAttachment(attachment)" class="ma-0 pa-0">
        <v-list-item-avatar>
          <v-icon>{{ getIconForMimeType(attachment.mimetype) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ attachment.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

import ResourceAttachmentsMixin from "./resource-attachments-mixin";

export default {
  name: 'ResourceAttachmentsList',
  mixins: [ResourceAttachmentsMixin],
};
</script>

<style scoped lang="scss">
@import "../style";

.ma-card-attachments {
  background: rgba(0, 0, 0, 0.03);
  border: $dark-thin-border;
  border-radius: $unit;
  padding: $pad/2;
  width: 100%;

  h4 {
    margin: 0;
  }
}
</style>
